import {ref, watch} from "vue";
import {useDevelStore} from "~/stores/devel.js";

export function getPreferedColorScheme() {
    const develStore = useDevelStore();
    if (!develStore.allowDarkMode) {
        return 'light';
    }
    try {
        if (sessionStorage.theme === 'dark' || (!('theme' in sessionStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            return 'dark';
        } else {
            return 'light';
        }
    }
    catch (e) {
        return 'light';
    }
}

export function setPreferedColorScheme(colorScheme) {
    sessionStorage.theme = colorScheme;
}

export function removePreferedColorScheme() {
    delete sessionStorage.removeItem('theme');
}

export function watchColorScheme(scheme) {
    watch(scheme, (colorScheme)=>{
        if (colorScheme === 'dark') {
            setPreferedColorScheme('dark');
            document.documentElement.classList.add('dark')
        } else if (colorScheme === 'light') {
            document.documentElement.classList.remove('dark')
            setPreferedColorScheme('light');
        }
        else {
            removePreferedColorScheme()
        }
    }, {immediate: true});
}

export function toggleDarkMode(currentScheme) {
    const develStore = useDevelStore();
    if (!develStore.allowDarkMode) {
        return 'light';
    }

    if (currentScheme === 'dark') {
        return 'light';
    }
    else {
        return 'dark';
    }
}

export function useDarkMode() {
    const develStore = useDevelStore();
    const preferedColorScheme = ref(getPreferedColorScheme());
    watchColorScheme(preferedColorScheme);
    return {
        toggleDarkMode() {
            preferedColorScheme.value = toggleDarkMode(preferedColorScheme.value)
        },
        preferedColorScheme,
        allowDarkMode: develStore.allowDarkMode
    }
}
