<template>
  <div class="Layouts/default">
    <ClientOnly>
      <DevTopBar v-if="isLocal"/>
    </ClientOnly>
    <template v-if="maintenance">
      <MaintenanceView />
    </template>
    <template v-else>
      <Header></Header>
      <slot/>
      <AppFooter />
    </template>

  </div>
</template>
<script setup>
import AppFooter from "~/components/layout/AppFooter.vue";
import Header from "~/components/layout/Header.vue";
import MaintenanceView from "~/components/layout/MaintenanceView.vue";
import {computed, onMounted} from "vue";
import {isLocalEnv, isProdEnv} from "~/src/functions/isLocalDev";
import DevTopBar from "~/components/layout/DevTopBar.vue";
import {useDevelStore} from "~/stores/devel.js";

const develStore = useDevelStore();
const isLocal = computed(() => isLocalEnv())
const route = useRoute();
const {data: maintenance} = useAsyncData('isMaintenance', async () => {
  await develStore.fetchMaintenance();
  return develStore.isMaintenance;
})

const head = computed(() => {
  const title = route.meta.title || route.path;
  const output = {
    titleTemplate: (title) => !title ? 'Épicerie Au Vrac Hasparren' : `${title} - Au Vrac Hasparren`,
    title,
    meta: [{name: 'og:title', content: `${title} - Au Vrac Hasparren`}],
    link: [],
    script: []
  }

  if (route.meta.description) {
    output.meta.push({ name: 'og:description', content: `${route.meta.description}` });
    output.meta.push({ name: 'description', content: `${route.meta.description}` });

  }


  // paygreen styles & script
  // @see https://developers.paygreen.fr/docs/installation
  // output.link = [
  //   ...output.link,
  //   {
  //     rel: 'preconnect',
  //     href: 'https://pgjs.paygreen.fr'
  //   },
  //   {
  //     rel: 'stylesheet',
  //     href: 'https://pgjs.paygreen.fr/latest/paygreen.min.css',
  //     crossorigin: ''
  //   },
  // ];
  // output.script = [
  //     ...output.script,
  //   {
  //     defer: '',
  //     src: 'https://pgjs.paygreen.fr/latest/paygreen.min.js',
  //     type: 'text/javascript',
  //     callback: () => isPaygreenLoaded.value = true
  //   },
  // ];
  return output;
});

useHead(head)


</script>
<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  @apply dark:bg-gray-800;

}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
