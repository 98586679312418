<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <header class="HeaderV2 bg-primary text-white shadow dark:shadow-none dark:bg-gray-900 w-full">
    <nav class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8" aria-label="Top">
      <div class="w-full py-6 flex items-center justify-between border-b border-green-700 md:border-none">
        <div class="flex items-center">
          <SiteBrandName width="100px" />
          <div class="hidden md:flex ml-8 md:space-x-4 lg:space-x-8 text-center items-center">
            <nuxt-link v-for="(link, k) in navigation" :key="`nav-link-${k}`" :to="link.to" class="text-base font-medium hover:text-indigo"
              :class="{
                'text-indigo dark:text-indigo-400': link.to.name === currentRouteName,
                'text-white dark:text-white': link.to.name !== currentRouteName,
              }"
              active-class="text-green-400"
            >
              {{ link.label }}
            </nuxt-link>
            <button class="text-base font-medium  hover:text-white dark:text-white" @click.prevent="loginModalOpened = !loginModalOpened" v-if="!isAuthenticated">
              Connexion
            </button>
            <ClientOnly>
              <DarkModeButton/>
            </ClientOnly>
          </div>
        </div>
        <div class="ml-10 flex xs:space-x-3 md:space-x-4 ">
          <!--<div class="flex"><NotificationsButton /></div>-->
          <div class="flex" v-if="develStore.enableShop"><ShoppingCartButton /></div>
          <div class="flex"><UserMenuButton /></div>
<!--          <a href="#" class="inline-block bg-indigo-500 py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75">Sign in</a>-->
<!--          <a href="#" class="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-indigo-600 hover:bg-indigo-50">Sign up</a>-->
        </div>
      </div>
      <div class="py-4 items-center flex flex-wrap justify-center space-x-6 md:hidden">
        <nuxt-link v-for="(link, k) in navigation"  :key="`nav-link-${k}`" :to="link.to" class="text-base font-medium hover:text-green-400"
          :class="{
            'text-green-400 dark:text-indigo-400': link.to.name === currentRouteName,
            'text-white dark:text-white': link.to.name !== currentRouteName,
          }"
          active-class="text-green-400"
        >
          {{ link.label }}
        </nuxt-link>
        <button class="text-base font-medium text-white hover:text-indigo-50" @click.prevent="loginModalOpened = true" v-if="!isAuthenticated">
          Connexion
        </button>
        <ClientOnly>
          <DarkModeButton/>
        </ClientOnly>
      </div>
      <LoginModal :open="loginModalOpened" @close="loginModalOpened = false"/>
    </nav>
  </header>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import UserMenuButton from "~/components/UserMenuButton.vue";
import ShoppingCartButton from "~/components/ShoppingCartButton.vue";
import SiteBrandName from "~/components/layout/SiteBrandName.vue";
import LoginModal from "~/components/user/LoginModal.vue";
import DarkModeButton from "~/components/DarkModeButton.vue";
import {useStrapiUserStore} from "~/stores/strapi-user.js";
import {useCartStore} from "~/stores/cart.js";
import {useDevelStore} from "~/stores/devel.js";

const userStore = useStrapiUserStore();
const cartStore = useCartStore();

const router = useRouter();
const loginModalOpened = ref(false)
const route = useRoute();
const currentRouteName = computed(() => route?.name)
const develStore = useDevelStore();

const menuLinks = [];
if (develStore.enableShop) {
  menuLinks.push({label: 'Produits', to: {name: 'product-catalog'}});
}
if (develStore.enableBlog) {
  menuLinks.push({ label: 'Actualités', to: { name: 'actualite'} });
}
if (develStore.enableShop) {
  menuLinks.push({label: 'Ma commande', to: {name: 'cart'}});
}
const navigation = ref(menuLinks)
const open = ref(false)
const isAuthenticated = computed(() => userStore.isAuthenticated);
const user = computed(() => userStore.user);
const cartItemsCount = computed(() => cartStore.cartItemsCount);
const cartTotal = computed(() => cartStore.total);
const loginClosed = () => {

  router.push({name: 'profile'});
}

</script>
