<template>
  <div @click="toggleDarkMode" class="navbar--icon cursor-pointer" :class="{hidden: !allowDarkMode}">
    <span class="sr-only">DarkMode</span>
    <SunIcon class="h-6 w-6" aria-hidden="true" v-if="preferedColorScheme === 'dark'"/>
    <MoonIcon class="h-6 w-6" aria-hidden="true" v-if="preferedColorScheme === 'light'"/>
  </div>
</template>
<script setup>
import MoonIcon from "@heroicons/vue/24/outline/MoonIcon.js";
import SunIcon from "@heroicons/vue/24/outline/SunIcon.js";
import {useDarkMode} from "~/composables/darkmode.js";

const {preferedColorScheme, toggleDarkMode, allowDarkMode} = useDarkMode();
</script>
