<template>
  <ul class="DevTopBar bg-red-600  w-full text-center text-white font-bold">
    <li v-if="isLocal">LOCAL</li>
    <li v-if="develStore.isMaintenance">
      <button type="button" class="link !text-white" @click.prevent="() => develStore.hideMaintenance()">Masquer la maintenance</button>
    </li>
    <li v-else>
      <button type="button" class="link !text-white" @click.prevent="() => develStore.showMaintenance()">Voir la maintenance</button>
    </li>
    <li><nuxt-link @click="() => develStore.hideMaintenance()" :to="{name: 'slot-view'}" class="link !text-white">Liste des créneaux</nuxt-link></li>
  </ul>
</template>
<script setup>
import {computed} from "vue";
import {isLocalEnv} from "~/src/functions/isLocalDev";
import {useDevelStore} from "~/stores/devel.js";
const isLocal = computed(() => isLocalEnv())
const develStore = useDevelStore();
</script>
<style>
ul.DevTopBar li {
  display: inline-block;
}

ul.DevTopBar  li:not(:first-child):before {
  content: ' — ';
}
</style>
