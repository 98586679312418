function hashCode(str) { // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function intToRGB(i){
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}

export const hexColorFromString = (text) => intToRGB(hashCode(text))
export const hexColorFromString1 = (text) => hexColorFromString(text)
export const hexColorFromString2 = (text) => intToRGB(hashCode(text.split('').reverse().join('')))
export const hexColorFromString3 = (text) => intToRGB(hashCode(text.split(/\.|\-| |\,|\//).filter(n => n.length > 1).join('')))
export const hexColorFromString4 = (text) => intToRGB(hashCode(text.split(/\.|\-| |\,|\//).filter(n => n.length > 1).reverse().join('')))
export const hexColorFromString5 = (text) => intToRGB(hashCode(text.split('').reverse().join('B')))
export const hexColorFromString6 = (text) => intToRGB(hashCode(text.split(/\.|\-| |\,|\//).filter(n => n.length > 1).join('B')))
export const hexColorFromString7 = (text) => intToRGB(hashCode(text.split(/\.|\-| |\,|\//).filter(n => n.length > 1).reverse().join('B')))
