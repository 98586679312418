<template>
  <span v-if="isAuthenticated">
    <span class="hidden md:inline">
      <img class="h-10 w-10 inline-flex rounded-full"
           :src="avatar" alt="" />
      {{name}}
    </span>
    <span class="inline md:hidden">
      Mon compte
    </span>
  </span>
  <span v-else>Mon compte</span>
</template>

<script setup>
import {computed} from "vue";
import {useStrapiUserStore} from '~/stores/strapi-user';
import * as colorFunctions from '~/src/functions/hexColorFromString';
import {getTextColor} from '~/src/functions/textColor';
const userStore = useStrapiUserStore();

const props = defineProps({
  color: {type: Number, default: () => 4}
});
const color = toRef(props, 'color');

const isAuthenticated = computed(() => userStore.isAuthenticated);
const user = computed(() => userStore.user);
const name = computed(() => user.value.getDisplayName());
const initiales = computed(() => {
  const names = name.value?.split?.(/( |\.|\-|@|_|\/)/).filter(n => n.length > 1);
  const first = names?.[0];
  const second = names?.[1];
  return (first?.[0] + second?.[0]+'').toUpperCase();
})

const jobs = 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';
const avatar = computed(() => {
  let colorFunction = 'hexColorFromString';
  if (color.value <= 7) {
    colorFunction += color.value+'';
  }
  const bgColor = colorFunctions[colorFunction](name.value);
  const txtColor = getTextColor(bgColor);
  return `https://dummyimage.com/sqrbut/${bgColor}/${txtColor}.jpg&text=${initiales.value}`;
});
</script>
