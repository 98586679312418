<template>
  <!-- Profile dropdown -->
  <Menu as="div" class="mx-3 relative">
    <MenuButton class="navbar--link !border-0">
      <span class="sr-only">Ouvrir le menu utilisateur</span>
      <UserName></UserName>
    </MenuButton>
    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
        <ClientOnly>

          <template v-if="isAuthenticated">
            <MenuItem v-slot="{ active }">
              <nuxt-link :to="{name: 'mon-profil'}" :class="[active ? 'bg-gray-100' : '', menuItemClasses]">Mon profil</nuxt-link>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a href="#" :class="[active ? 'bg-gray-100' : '', menuItemClasses]" class="line-through">Mes commandes</a>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a href="#" class="link" type="button" :class="[active ? 'bg-gray-100' : '', menuItemClasses]" @click.prevent="logOut"><slot>Déconnexion</slot></a>
            </MenuItem>
          </template>
          <template v-else>
            <MenuItem v-slot="{ active }">
              <nuxt-link :to="{name: 'connexion'}" :class="[active ? 'bg-gray-100' : '', menuItemClasses]">Connexion</nuxt-link>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <nuxt-link :to="{name: 'inscription'}" :class="[active ? 'bg-gray-100' : '', menuItemClasses]">Inscription</nuxt-link>
            </MenuItem>
          </template>
        </ClientOnly>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script setup>
import {computed, ref} from "vue";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import UserName from "~/components/UserName.vue";
import {useStrapiUserStore} from "~/stores/strapi-user.js";

const userStore = useStrapiUserStore();

const isAuthenticated = computed(() => userStore.isAuthenticated);

const user = computed(() => userStore.user);
const name = computed(() => userStore.user.getDisplayName())
const logOut = () => {
  // Demander à l'utilisateur si il souhaite que son panier soit supprimer
  userStore.logout()
}
const menuItemClasses = ref('block px-4 py-2 text-sm text-gray-700 dark:text-gray-200');
</script>
