<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <button type="button" @click.prevent="open = !open" class="header__icon-button--link" :title="buttonLabel">
    <span class="sr-only">{{ buttonLabel }}</span>
    <ShoppingCartIcon class="inline h-6 w-6" aria-hidden="true"></ShoppingCartIcon>
    <span class="relative pl-1" v-if="showCartCount && cartCount > 0">
      <span class="absolute inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800 scale-75 left-4  md:-left-4 -top-3 opacity-80">
        <Spinner spin v-if="cartUpdateInProgress"/>
        <span v-else>{{cartCount}}</span>
      </span>
    </span>
    <span class="pl-1 block md:inline" v-if="showPrice && cartTotal > 0">
      {{formatPrice(cartTotal)}}&nbsp;€
    </span>
  </button>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"  />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      Votre panier
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="-m-2 p-2 text-gray-400 hover:text-gray-500" @click="open = false">
                        <span class="sr-only">Fermer le panier</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div class="mt-8">
                    <div class="flow-root">
                      <ul role="list" class="-my-6 divide-y divide-gray-200">
                        <li v-for="(orderItem, k) in orderItems" :key="k" class="py-6 flex">
                          <picture class=" block flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                            <img :src="`${orderItem?.product?.mainImage?.getSrcUrl('thumbnail')}`" class="w-full h-full object-center object-cover">
                          </picture>

                          <div class="ml-4 flex-1 flex flex-col">
                            <div>
                              <div class="flex justify-between text-base font-medium text-gray-900" :key="`${orderItem.reference}-${orderItem.getReference()}-${orderItem.productId}`">
                                <h3>
                                  <nuxt-link :to="{name: 'produit-slug', params: {slug: orderItem?.product?.slug}}">
                                    {{ orderItem?.getReference() }}
                                  </nuxt-link>
                                </h3>
                                <p class="ml-4 has-tooltip">
                                  <span class="tooltip rounded shadow-lg p-1 bg-gray-300 text-primary -mt-16 opacity-75">
                                    {{ orderItem.quantity }}{{ getUnitLabel(orderItem.unit) }} x {{ formatPrice(orderItem.price) }}&nbsp;€<br>
                                    Soit {{ orderItem.formatTotal() }}
                                  </span>
                                  {{ orderItem?.formatTotal() }}
                                </p>
                              </div>
                            </div>
                            <AddToCart :product-id="orderItem.productId" :auto-remove="false" :show-unit-price="true"></AddToCart>
                          </div>
                        </li>
                        <li v-if="cartCount === 0">
                          <EmptyCart @click="open = false"/>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
                  <template v-if="cartCount">

                    <div class="flex justify-between text-base font-medium text-gray-900">
                      <p>Total</p>
                      <p>{{formatPrice(cartTotal)}}&nbsp;€</p>
                    </div>
                    <p class="mt-0.5 text-sm text-gray-500">Paiement et planification du retrait lors de la commande.</p>
                    <div class="mt-6">
                      <ValidateCartButton
                        @click.prevent="validateCart"
                        :disabled="validating"
                      />

                    </div>
                  </template>
                  <div class="mt-6 flex justify-center text-sm text-center text-gray-500">
                    <p>
                      <span v-if="cartCount">ou </span>
                      <button type="button" class="text-indigo-600 font-medium hover:text-indigo-500" @click="open = false">Continuer mes achats<span aria-hidden="true"> &rarr;</span></button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
      <LoginModal :open="loginOpen" @close="loginClosed" />
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {getUnitLabel, formatPrice} from "~/src/models/Product";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import XIcon from '@heroicons/vue/24/outline/XMarkIcon.js'
import ShoppingCartIcon from '@heroicons/vue/24/outline/ShoppingCartIcon.js'
import AddToCart from "./AddToCart.vue";
import LoginModal from "~/components/user/LoginModal.vue";
import {useApi} from "~/src/api";
import {useStrapiUserStore} from '~/stores/strapi-user';
import {useCartStore} from '~/stores/cart';
import {useProductsStore} from '~/stores/products';
import EmptyCart from "~/components/order/EmptyCart.vue";
import Spinner from "~/components/Spinner.vue";
import ValidateCartButton from "~/components/order/ValidateCartButton.vue";
import {useValidateCart} from "~/composables/useValidateCart.js";

const api = useApi();
const props = defineProps({
  showPrice: { type: Boolean, default: () => true},
  showCartCount: { type: Boolean, default: () => true},
  buttonLabel: { type: String, default: () => 'Voir le panier'}
});

const userStore = useStrapiUserStore();
const cartStore = useCartStore();
const productsStore = useProductsStore();
const {validateCart, validating, loginOpen, loginClosed} = useValidateCart();

const open = ref(false)
const orderItems = computed(() => cartStore.cart);
const isAuthenticated = computed(() => userStore.isAuthenticated);
const cartTotal = computed(() => cartStore.total);
const cartUpdateInProgress = computed(() => cartStore.createOrUpdateInProgress);
const cartCount = computed(() => cartStore.cartItemsCount)
</script>
